<style lang="scss">
@import "~@/assets/css/var";
.nav-all {
  width: 1200px;
  margin: -30px auto 0;
  text-align: center;
  .el-select {
    input {
      line-height: 4844;
      height: 44px;
      font-size: 14px;
      border-color: $main;
    }
  }
  .el-button {
    height: 44px;
    font-size: 15px;
    border: 1px solid $main;
  }

  .el-autocomplete {
    width: 570px;
    box-shadow: 0 3px 15px 0 rgba(0, 17, 108, 0.36);
    border-radius: 6px 0 0 6px;
    input {
      line-height: 44px;
      height: 44px;
      font-size: 14px;
      padding: 0 15px;
      border-color: $main;
    }
    .el-input__icon {
      padding: 0 10px;
      font-size: 19px;
    }
    .el-input-group__append {
      width: 100px;
      text-align: center;
      background: $main;
      color: #fff;
      border-color: $main;
      font-size: 16px;
      cursor: pointer;
      transition: background 0.3s;
      &:hover {
        background: $main + ee;
      }
    }
  }

  &-product {
    display: flex;
    margin: 40px 0 80px;
    padding: 30px 50px;
    background: #fff url("~@/assets/img/nav-left/product-back.png") no-repeat
      right bottom / 277px auto;
    .hot-recommend-panel {
      display: flex;
      flex-direction: column;
      height: 290px;
      width: 400px;
      flex-wrap: wrap;
    }
    .hot-recommend {
      margin-right: 40px;
    }

    &__title {
      line-height: 22px;
      font-size: 22px;
      text-align: left;
      font-weight: 400;
      color: #000;
      margin-bottom: 35px;
      img {
        width: 22px;
      }
    }
    &__line {
      width: 1px;
      height: 350px;
      background: #dcdcdc;
      margin: 0 80px;
    }
    &__item {
      flex: 1;
    }
    &__desc {
      text-align: left;
      font-size: 15px;
      line-height: 20px;
      font-weight: 400;
      color: #666;
      margin-bottom: 20px;
      cursor: pointer;
      &:hover {
        color: $main;
      }
      &:before {
        content: "·";
        color: $main;
        font-weight: bold;
        font-size: 20px;
        padding-right: 3px;
      }
    }
  }

  &-corp {
    position: relative;
    text-align: left;
    min-height: 400px;
    overflow: visible;
    &__title {
      background: #6880fc;
      color: #fff;
      line-height: 50px;
      padding: 0 20px;
      font-size: 16px;
      font-weight: 300;
    }
    &__kind {
      position: -webkit-sticky;
      position: sticky;
      top: 100px;
      float: left;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      width: 188px;
      background: #fff;
      border: 1px solid #dcdcdc;
      box-shadow: 5px 7px 14px 0 rgba(182, 182, 182, 0.2);
    }

    .el-submenu {
      &__title {
        padding: 0 !important;
        display: flex;
        align-items: center;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #666;
        p {
          padding-left: 20px;
          line-height: 16px;
          border-left: 2px solid transparent;
        }
        i {
          font-weight: bold;
        }
      }
      .el-menu-item {
        height: 36px;
        line-height: 36px;
        padding: 0 0 0 40px;
        min-width: unset;
        font-size: 14px;
        color: #666;
      }

      &.is-active {
        .el-submenu__title {
          p {
            border-color: #6880fc;
          }
          i {
            color: #6880fc;
          }
        }
      }
    }

    &__panel {
      margin-left: 245px;
      margin-bottom: 50px;
    }
    &__name {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
      color: #000000;
      padding-bottom: 28px;
      margin-bottom: 30px;
      border-bottom: 1px solid #dcdcdc;
      img {
        width: 28px;
        margin-right: 14px;
      }
    }

    &__desc {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: #000000;
      padding-bottom: 20px;
      &:before {
        font-size: 20px;
        content: "-";
        color: #6880fc;
        font-weight: bold;
        padding-right: 10px;
      }
    }
    &__assist {
      display: flex;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      width: 180px;
      margin-bottom: 10px;
      box-sizing: border-box;
      cursor: pointer;
      &:before {
        display: inline-block;
        font-size: 20px;
        line-height: 20px;
        content: "·";
        color: #333333;
        font-weight: bold;
        padding-right: 5px;
      }
      &:hover {
        color: #6880fc;
        &:before {
          color: #6880fc;
        }
      }
    }
  }
}
</style>

<template>
  <div style="background: #f6f6f6;">
    <img src="@/assets/img/nav-left/banner.png" alt="">

    <div class="nav-all">
      <ts-search class="flex flex-center" :param="{}" style="justify-content: center;"></ts-search>
      <div class="nav-all-product">
        <div class="nav-all-product__item">
          <p class="nav-all-product__title">
            <img class="hot" src="@/assets/img/nav-left/fire.png" alt="fire">
            热门产品
          </p>
          <div class="hot-recommend-panel">
            <div class="hot-recommend" v-for="(item, i) in product.hotList" :key="item.id" @click="$help.openUrl(item)">
              {{item.viewName}}
              <i>0{{i + 1}}</i>
              <img v-if="item.hot" class="hot" src="@/assets/img/hot.png" alt="hot">
            </div>

          </div>
        </div>
        <div class="nav-all-product__line"></div>
        <div class="nav-all-product__item">
          <p class="nav-all-product__title">新品发布</p>
          <p class="nav-all-product__desc" v-for="item in product.newList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</p>

        </div>
        <div class="nav-all-product__line"></div>
        <div class="nav-all-product__item">
          <p class="nav-all-product__title">产品动态</p>
          <p class="nav-all-product__desc" v-for="item in product.productList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</p>
          <p class="nav-all-product__title">其他</p>
          <p class="nav-all-product__desc" v-for="item in product.otherList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</p>
        </div>
      </div>

      <div class="nav-all-corp">
        <div class="nav-all-corp__kind">
          <p class="nav-all-corp__title">产品类别</p>
          <el-menu :default-openeds="['1']" active-text-color="#6880FC" unique-opened @select="menuChange">
            <el-submenu index="1">
              <template #title>
                <p>企业所需入口</p>
              </template>
              <el-menu-item index="corp-1" @click="handleToChart('corp-1')">企业信息</el-menu-item>
              <el-menu-item index="corp-2" @click="handleToChart('corp-2')">药品标准</el-menu-item>
              <el-menu-item index="corp-3" @click="handleToChart('corp-3')">药品研发</el-menu-item>
              <el-menu-item index="corp-4" @click="handleToChart('corp-4')">竞争力分析</el-menu-item>
            </el-submenu>
            <el-submenu index="2">
              <template #title>
                <p>服务商所需入口</p>
              </template>
              <el-menu-item index="service-1" @click="handleToChart('service-1')">上市药品</el-menu-item>
              <el-menu-item index="service-2" @click="handleToChart('service-2')">合理用药</el-menu-item>
              <el-menu-item index="service-3" @click="handleToChart('service-3')">市场行情</el-menu-item>
              <el-menu-item index="service-4" @click="handleToChart('service-4')">药品检查</el-menu-item>
            </el-submenu>
            <el-submenu index="3">
              <template #title>
                <p>助手分析入口</p>
              </template>
              <el-menu-item index="analysis-1" @click="handleToChart('analysis-1')">药品穿透</el-menu-item>
              <el-menu-item index="analysis-2" @click="handleToChart('analysis-2')">医学文献</el-menu-item>
              <el-menu-item index="analysis-3">市场行情</el-menu-item>
              <el-menu-item index="analysis-4" @click="handleToChart('analysis-3')">销售数据</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>

        <div class="nav-all-corp__panel">
          <div class="nav-all-corp__item">
            <p class="nav-all-corp__name">
              <img src="@/assets/img/nav-left/black-corp.png" alt="">
              企业所需入口
            </p>
            <div class="nav-all-corp__info">
              <p id="corp-1" class="nav-all-corp__desc">企业信息</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in corp.infoList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
            <div class="nav-all-corp__info">
              <p id="corp-2" class="nav-all-corp__desc">药品标准</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in corp.drugList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
            <div class="nav-all-corp__info">
              <p id="corp-3" class="nav-all-corp__desc">药品研发</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in corp.devList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
            <div class="nav-all-corp__info">
              <p id="corp-4" class="nav-all-corp__desc">竞争力分析</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in corp.newsList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="nav-all-corp__panel">
          <div class="nav-all-corp__item">
            <p class="nav-all-corp__name">
              <img src="@/assets/img/nav-left/black-corp.png" alt="">
              服务商所需入口
            </p>
            <div class="nav-all-corp__info">
              <p id="service-1" class="nav-all-corp__desc">上市药品</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in service.drugList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
            <div class="nav-all-corp__info">
              <p id="service-2" class="nav-all-corp__desc">合理用药</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in service.reasonableList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
            <div class="nav-all-corp__info">
              <p id="service-3" class="nav-all-corp__desc">市场行情</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in service.marketList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
            <div class="nav-all-corp__info">
              <p id="service-4" class="nav-all-corp__desc">药品检查</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in service.checkList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="nav-all-corp__panel">
          <div class="nav-all-corp__item">
            <p class="nav-all-corp__name">
              <img src="@/assets/img/nav-left/black-corp.png" alt="">
              助手分析入口
            </p>
            <div class="nav-all-corp__info">
              <p id="analysis-1" class="nav-all-corp__desc">药品穿透</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in analysis.drugList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
            <div class="nav-all-corp__info">
              <p id="analysis-2" class="nav-all-corp__desc">医学文献</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in analysis.docList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
            <div class="nav-all-corp__info">
              <p id="analysis-3" class="nav-all-corp__desc">销售数据</p>
              <div class="flex" style="padding: 0 20px; flex-wrap: wrap">
                <div class="nav-all-corp__assist" v-for="item in analysis.saleList" :key="item.id" @click="$help.openUrl(item)">{{item.viewName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      visitId: new Date().getTime(),
      pageId: 'navAll',
      pageName: '导航-全部产品',

      searchTxt: '',

      product: {
        hotList: [],
        newList: [],
        productList: [],
        otherList: [],
      },

      corp: {
        infoList: [],
        drugList: [],
        devList: [],
        newsList: [],
      },
      service: {
        drugList: [],
        reasonableList: [],
        marketList: [],
        checkList: [],
      },
      analysis: {
        drugList: [],
        docList: [],
        saleList: [],
      }
    }
  },
  created() {
    // region product
    this.$help.getUrlIndex(2, 8, undefined, this.visitId)
      .then(res => {
        this.product.hotList = res.data.records
      })

    this.$help.getUrlIndex(3, 6, undefined, this.visitId)
      .then(res => {
        this.product.newList = res.data.records
      })

    this.$help.getUrlIndex(4, 3, undefined, this.visitId)
      .then(res => {
        this.product.productList = res.data.records
      })

    this.$help.getUrlIndex(5, 3, undefined, this.visitId)
      .then(res => {
        this.product.otherList = res.data.records
      })
    // endregion

    // region corp
    this.$help.getUrlIndex(6, 10, undefined, this.visitId)
      .then(res => {
        this.corp.infoList = res.data.records
      })
    this.$help.getUrlIndex(7, 10, undefined, this.visitId)
      .then(res => {
        this.corp.drugList = res.data.records
      })
    this.$help.getUrlIndex(8, 10, undefined, this.visitId)
      .then(res => {
        this.corp.devList = res.data.records
      })
    this.$help.getUrlIndex(9, 10, undefined, this.visitId)
      .then(res => {
        this.corp.newsList = res.data.records
      })
    // endregion

    // region service
    this.$help.getUrlIndex(10, 20, undefined, this.visitId)
      .then(res => {
        this.service.drugList = res.data.records
      })
    this.$help.getUrlIndex(11, 20, undefined, this.visitId)
      .then(res => {
        this.service.reasonableList = res.data.records
      })
    this.$help.getUrlIndex(12, 20, undefined, this.visitId)
      .then(res => {
        this.service.marketList = res.data.records
      })
    this.$help.getUrlIndex(13, 20, undefined, this.visitId)
      .then(res => {
        this.service.checkList = res.data.records
      })
    // endregion

    // region analysis
    this.$help.getUrlIndex(14, 20, undefined, this.visitId)
      .then(res => {
        this.analysis.drugList = res.data.records
      })

    this.$help.getUrlIndex(15, 20, undefined, this.visitId)
      .then(res => {
        this.analysis.docList = res.data.records
      })

    this.$help.getUrlIndex(16, 20, undefined, this.visitId)
      .then(res => {
        this.analysis.saleList = res.data.records
      })
    // endregion

    this.$help.socket.send(this)
  },
  methods: {
    querySearch(queryString, cb) {
      this.$api.get('index/drugSearch', { keyword: queryString }).then(res => {
        cb(res.data.records);
      });
    },
    search() {
      if (this.searchTxt) {
        this.$router.push({
          path: `/drug/${this.$help.encryption(this.searchTxt)}`,
        })
      }
    },
    menuChange(menu) {
      console.log(menu)
    },
    handleToChart(event) {
      this.$nextTick(() => {
        const domToTop = document.getElementById(event).offsetTop
        this.scrollToLocation(domToTop)
      })
    },
    // 页面滑动到指定位置
    scrollToLocation(toHeight) {
      window.scrollTo({
        top: toHeight +680,
        behavior: "smooth"
      });
    }


  }
}
</script>